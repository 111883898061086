import { produce } from "immer";

import { CycleType } from "types/CycleType";
import { ActionTypes, CycleActions } from "store/cycle/actions";

export const initialState = {
  loading: false,
  data: {} as CycleType,
};

export default function reducer(
  state: typeof initialState,
  action: CycleActions
) {
  switch (action.type) {
    case ActionTypes.CYCLE_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });

    case ActionTypes.CYCLE_GET_DATA:
      return produce(state, (draft) => {
        draft.data = action.payload;
      });

    default:
      return state;
  }
}
