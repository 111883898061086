import httpAxios from "repositories";

import { SchedulesResponseType, SchedulesType } from "types/SchedulesType";

export default class SchedulesRepository {
  async get() {
    const { data } = await httpAxios.get<{ data: SchedulesResponseType }>(
      "/api/guide/horarios"
    );
    return data;
  }

  async set(payload: SchedulesType) {
    const { data } = await httpAxios.post("/api/guide/horarios", payload);
    return data;
  }
}
