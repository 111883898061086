import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  CycleActions,
  cycleLoadingAction,
  cycleGetDataAction,
} from "store/cycle/actions";
import reducer, { initialState } from "store/cycle/reducer";

import CycleRepository from "repositories/cycle";

const repository = new CycleRepository();

export type ContextDataType = {
  cycle: typeof initialState;
  dispatch: React.Dispatch<CycleActions>;
};

export const CycleContext = createContext({} as ContextDataType);

const CycleProvider = ({ children }: { children: ReactNode }) => {
  const [state, action] = useReducer(reducer, initialState);

  const [cycle, dispatch] = useMemo(() => [state, action], [state, action]);

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(cycleLoadingAction(true));

        const { data } = await repository.get();

        dispatch(cycleGetDataAction(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(cycleLoadingAction(false));
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <CycleContext.Provider value={{ cycle, dispatch }}>
      {children}
    </CycleContext.Provider>
  );
};

export const useCycle = () => {
  const context = useContext(CycleContext);

  if (!context) {
    throw new Error("useCycle must be used within an CycleProvider.");
  }

  return context;
};

export default CycleProvider;
