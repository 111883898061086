import { CycleType } from "types/CycleType";

export enum ActionTypes {
  CYCLE_LOADING = "CYCLE_LOADING",
  CYCLE_GET_DATA = "CYCLE_GET_DATA",
}

export interface CycleLoadingType {
  type: ActionTypes.CYCLE_LOADING;
  payload: boolean;
}

export interface CycleGetDataType {
  type: ActionTypes.CYCLE_GET_DATA;
  payload: CycleType;
}

export type CycleActions = CycleLoadingType | CycleGetDataType;

export function cycleLoadingAction(payload: boolean): CycleLoadingType {
  return {
    type: ActionTypes.CYCLE_LOADING,
    payload,
  };
}

export function cycleGetDataAction(payload: CycleType): CycleGetDataType {
  return {
    type: ActionTypes.CYCLE_GET_DATA,
    payload,
  };
}
