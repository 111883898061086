import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  SchedulesActions,
  schedulesGetHoursAction,
  schedulesIsFilledHoursAction,
  schedulesLoadingAction,
} from "store/schedules/actions";
import reducer, { initialState } from "store/schedules/reducer";

import SchedulesRepository from "repositories/schedules";
import { WeekType } from "types/SchedulesType";

const repository = new SchedulesRepository();

export type ContextDataType = {
  schedules: typeof initialState;
  dispatch: React.Dispatch<SchedulesActions>;
};

export const SchedulesContext = createContext({} as ContextDataType);

const SchedulesProvider = ({ children }: { children: ReactNode }) => {
  const [state, action] = useReducer(reducer, initialState, (prevState) => ({
    ...prevState,
    menuOpen: prevState.isFilledHours,
  }));

  const [schedules, dispatch] = useMemo(() => [state, action], [state, action]);

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(schedulesLoadingAction(true));

        const { data } = await repository.get();

        if ("created_at" in data) {
          delete data.created_at;
        }
        if ("customer_id" in data) {
          delete data.customer_id;
        }
        if ("updated_at" in data) {
          delete data.updated_at;
        }
        if ("id" in data) {
          delete data.id;
        }

        dispatch(schedulesGetHoursAction(data));

        const sum = Object.keys(data).reduce(
          (acumulador, chave) => acumulador + data[chave as WeekType],
          0
        );
        dispatch(schedulesIsFilledHoursAction(sum >= 3));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(schedulesLoadingAction(false));
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <SchedulesContext.Provider value={{ schedules, dispatch }}>
      {children}
    </SchedulesContext.Provider>
  );
};

export const useSchedules = () => {
  const context = useContext(SchedulesContext);

  if (!context) {
    throw new Error("useSchedules must be used within an SchedulesProvider.");
  }

  return context;
};

export default SchedulesProvider;
