import { FC, useState } from "react";
import {
  Box,
  Stack,
  IconButton,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";

import Scrollbar from "components/elements/ScrollBar";
import ArrowLeftToLine from "icons/duotone/ArrowLeftToLine";

import MultiLevelMenu from "./MultiLevelMenu";
import MobileSidebar from "./MobileSidebar";
import ActionsIcons from "./layout-parts/ActionsIcons";

const TOP_HEADER_AREA = 70;

const SidebarWrapper = styled(Box)<{ compact: number }>(
  ({ theme, compact }) => ({
    height: "100vh",
    position: "fixed",
    width: compact ? 86 : 280,
    transition: "all .2s ease",
    zIndex: 98,
    backgroundColor: theme.palette.primary.main,
    "&:hover": compact && { width: 280 },
  })
);

const NavWrapper = styled(Box)(() => ({
  marginTop: 16,
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));

const StyledArrow = styled(ArrowLeftToLine)(() => ({
  display: "block",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));

// -----------------------------------------------------------------------------
type DashboardSidebarProps = {
  sidebarCompact: boolean;
  showMobileSideBar: boolean;
  setSidebarCompact: () => void;
  setShowMobileSideBar: () => void;
};
// -----------------------------------------------------------------------------

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar,
    setSidebarCompact,
  } = props;

  const [onHover, setOnHover] = useState(false);

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const COMPACT = sidebarCompact && !onHover ? 1 : 0;

  if (downLg) {
    return (
      <MobileSidebar
        sidebarCompact={!!COMPACT}
        showMobileSideBar={!!showMobileSideBar}
        setShowMobileSideBar={setShowMobileSideBar}
      />
    );
  }

  return (
    <SidebarWrapper
      compact={sidebarCompact ? 1 : 0}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => sidebarCompact && setOnHover(false)}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pt={3}
        pr={2}
        pl={"22px"}
        pb={1}
        height={TOP_HEADER_AREA}
      >
        <Stack direction="row" alignItems="center">
          <img src="/logo-granxperts.svg" alt="logo" width={180} />
        </Stack>
        <Box mx={"auto"}></Box>

        <StyledIconButton
          onClick={setSidebarCompact}
          sx={{ display: COMPACT ? "none" : "block" }}
        >
          <StyledArrow sx={{ color: (theme) => theme.palette.common.white }} />
        </StyledIconButton>
      </Stack>

      {!COMPACT && <ActionsIcons />}

      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: "hidden",
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`,
        }}
      >
        <NavWrapper>
          <MultiLevelMenu sidebarCompact={!!COMPACT} />
        </NavWrapper>
      </Scrollbar>
    </SidebarWrapper>
  );
};

export default DashboardSidebar;
