import { produce } from "immer";

import { SchedulesType } from "types/SchedulesType";
import { ActionTypes, SchedulesActions } from "store/schedules/actions";

export const initialState = {
  loading: false,
  menuOpen: false,
  isFilledHours: false,
  hours: {} as SchedulesType,
};

export default function reducer(
  state: typeof initialState,
  action: SchedulesActions
) {
  switch (action.type) {
    case ActionTypes.SCHEDULES_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });

    case ActionTypes.SCHEDULES_GET_HOURS:
      return produce(state, (draft) => {
        draft.hours = action.payload;
      });

    case ActionTypes.SCHEDULES_IS_FILLED_HOURS:
      return produce(state, (draft) => {
        draft.isFilledHours = action.payload;
      });

    case ActionTypes.SCHEDULES_TOGGLE_MENU:
      return produce(state, (draft) => {
        draft.menuOpen = action.payload;
      });

    default:
      return state;
  }
}
