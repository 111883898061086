import { Theme } from "@mui/material";
import { lightTheme } from "../constants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    GreyOutlined: true;
    dashed: true;
    white: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    grey: true;
  }
}

const components = (theme: Theme): any => {
  const { shadows } = theme;
  const {
    error,
    divider,
    action,
    grey,
    primary,
    secondary,
    text,
    success,
    warning,
    info,
    background,
  } = theme.palette;

  return {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
        },
        body: { width: "100%", height: "100%" },
        a: { textDecoration: "none", color: primary.main },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        "#root": { width: "100%", height: "100%" },
        "#nprogress .bar": {
          zIndex: "2000 !important",
          backgroundColor: primary.main,
        },
        "&::-webkit-scrollbar": {
          width: "0.4rem",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "2rem",
          background: primary.main,
        },

        "div:where(.swal2-container)": {
          zIndex: "1999 !important",
          "button:where(.swal2-styled)": {
            textTransform: "uppercase",
          },
          "button:where(.swal2-styled).swal2-confirm": {
            backgroundColor: `${primary.main}!important`,
          },
          "button:where(.swal2-styled).swal2-deny": {
            backgroundColor: `${error.main}!important`,
          },
          "button:where(.swal2-styled).swal2-cancel": {
            backgroundColor: `transparent!important`,
            color: `${grey[500]}!important`,
          },
          "div:where(.swal2-icon).swal2-warning.swal2-icon-show": {
            borderColor: `${warning.main}!important`,
            color: `${warning.dark}!important`,
          },
          "div:where(.swal2-icon).swal2-success.swal2-icon-show": {
            borderColor: `${success.main}!important`,
            color: `${success.dark}!important`,
          },
          "div:where(.swal2-icon).swal2-error.swal2-icon-show": {
            borderColor: `${error.main}!important`,
            color: `${error.dark}!important`,
          },
          "div:where(.swal2-icon).swal2-info.swal2-icon-show": {
            borderColor: `${info.main}!important`,
            color: `${info.dark}!important`,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.severity === "warning" && {
            backgroundColor: warning.main,
            color: warning.dark,

            "& .MuiAlert-icon": {
              color: warning.dark,
            },
          }),
          ...(ownerState.severity === "info" && {
            backgroundColor: info.main,
            color: info.dark,

            "& .MuiAlert-icon": {
              color: info.dark,
            },
          }),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: { height: "75%", width: "75%" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
          color: "inherit",
          boxShadow: "none",
          padding: "0.6rem 1.5rem",
          fontSize: 12,
          fontWeight: 600,
          minWidth: 84,
          "&.Mui-disabled": {
            backgroundColor: action.disabledBackground,
            color: text.disabled,
          },
          "&.MuiButton-containedError": {
            backgroundColor: error.main,
            color: "white",
            boxShadow: "none",
          },
          "&.MuiButton-containedSuccess": {
            color: "white",
            "&:hover": { boxShadow: "none", backgroundColor: success.main },
          },
          "& .MuiButton-startIcon .MuiSvgIcon-root": { fontSize: 16 },
        },
        outlinedPrimary: { borderColor: divider, color: text.primary },
        containedPrimary: { color: "white", "&:hover": { boxShadow: "none" } },
        containedSecondary: {
          color: "white",
          "&:hover": {
            backgroundColor: secondary.dark,
            boxShadow: "none",
          },
        },
      },
      variants: [
        {
          props: { variant: "GreyOutlined" },
          style: {
            border: `1px solid ${divider}`,
            color: (theme: Theme) =>
              lightTheme(theme) ? text.primary : "#fff",
            "&:hover": { backgroundColor: "transparent" },
          },
        },
        {
          props: { variant: "GreyOutlined", size: "small" },
          style: { padding: "0.3rem 0.8rem" },
        },
        {
          props: { variant: "text" },
          style: { padding: 0, "&:hover": { backgroundColor: "transparent" } },
        },

        {
          props: { variant: "white" },
          style: {
            padding: "12px 24px",
            backgroundColor: "white",
            color: text.disabled,
          },
        },

        {
          props: { variant: "dashed" },
          style: {
            height: 36,
            padding: 0,
            minWidth: 36,
            maxWidth: 36,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px dashed ${divider}`,
            borderRadius: "50%",
            overflow: "hidden",
          },
        },
      ],
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "h6" },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow: 'none',
          boxShadow: shadows[1],
          // border: "1px solid #E5EAF2",
          borderRadius: 8,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: { minWidth: "auto", marginRight: "16px" },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: "none" },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: { color: "#FFD600" },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: { borderColor: "transparent" },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": { backgroundColor: "transparent" },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:last-of-type": {
            "& .MuiTableCell-root": { borderColor: background.default },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `5px solid ${background.default}`,
          fontSize: 13,
          fontWeight: 500,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: text.secondary,
          textTransform: "none",
          fontSize: 12,
          fontWeight: 500,
          padding: 0,
          minWidth: "auto",
          marginLeft: "1rem",
          marginRight: "1rem",
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiButtonBase-root:last-of-type": {
            marginRight: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {},
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          "& .MuiPopover-paper": {
            // boxShadow: shadows[1],
            borderRadius: 4,
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: { fontFamily: "'Montserrat', sans-serif" },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 7,
          borderRadius: 14,
          backgroundColor: primary[100],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: { backgroundColor: grey[200] },
        track: { opacity: 1, backgroundColor: action.disabledBackground },
        switchBase: {
          "&.Mui-checked .MuiSwitch-thumb": {
            color: "#2499EF !important",
            backgroundColor: primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // "&::placeholder": { opacity: 0.86, color: "#42526e" },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiOutlinedInput-input": {
              fontWeight: 500,
              fontSize: 12,
              color: theme.palette.text.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${divider}`,
              borderRadius: "8px",
              borderColor: theme.palette.action.disabled,
            },
            "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
              {
                borderColor: theme.palette.action.hover,
              },
            "& .MuiInputLabel-root": {
              fontWeight: 500,
              color: theme.palette.text.disabled,
            },
            "& .MuiInputLabel-root.Mui-focused": { fontWeight: 600 },
            "& .MuiSvgIcon-root": { color: theme.palette.text.disabled },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${divider}`,
            },
            "& input::placeholder": { color: text.disabled },
          },
        },
      ],
    },

    MuiSvgIcon: {
      styleOverrides: { root: { "& .secondary": { opacity: 0.4 } } },
    },
  };
};

export default components;
