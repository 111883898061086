import { SchedulesType } from "types/SchedulesType";

export enum ActionTypes {
  SCHEDULES_LOADING = "SCHEDULES_LOADING",
  SCHEDULES_GET_HOURS = "SCHEDULES_GET_HOURS",
  SCHEDULES_IS_FILLED_HOURS = "SCHEDULES_IS_FILLED_HOURS",
  SCHEDULES_TOGGLE_MENU = "SCHEDULES_TOGGLE_MENU",
}

export interface SchedulesLoadingType {
  type: ActionTypes.SCHEDULES_LOADING;
  payload: boolean;
}

export interface SchedulesGetHoursType {
  type: ActionTypes.SCHEDULES_GET_HOURS;
  payload: SchedulesType;
}

export interface SchedulesIsFilledHourSType {
  type: ActionTypes.SCHEDULES_IS_FILLED_HOURS;
  payload: boolean;
}

export interface SchedulesToogleMenuType {
  type: ActionTypes.SCHEDULES_TOGGLE_MENU;
  payload: boolean;
}

export type SchedulesActions =
  | SchedulesLoadingType
  | SchedulesGetHoursType
  | SchedulesIsFilledHourSType
  | SchedulesToogleMenuType;

export function schedulesLoadingAction(payload: boolean): SchedulesLoadingType {
  return {
    type: ActionTypes.SCHEDULES_LOADING,
    payload,
  };
}

export function schedulesGetHoursAction(
  payload: SchedulesType
): SchedulesGetHoursType {
  return {
    type: ActionTypes.SCHEDULES_GET_HOURS,
    payload,
  };
}

export function schedulesIsFilledHoursAction(
  payload: boolean
): SchedulesIsFilledHourSType {
  return {
    type: ActionTypes.SCHEDULES_IS_FILLED_HOURS,
    payload,
  };
}

export function schedulesToogleMenuAction(
  payload: boolean
): SchedulesToogleMenuType {
  return {
    type: ActionTypes.SCHEDULES_TOGGLE_MENU,
    payload,
  };
}
