import httpAxios from "repositories";

import { CycleType, CycleFormData } from "types/CycleType";

export default class CycleRepository {
  async get() {
    const { data } = await httpAxios.get<{ data: CycleType }>(
      "/api/guide/ciclos"
    );

    return data;
  }

  async patch(payload: CycleFormData) {
    const { data } = await httpAxios.post("/api/guide/ciclos", payload);

    return data;
  }
}
